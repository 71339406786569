import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'
var urutan = 0
export default {
  data(){
    return {
      search: '',
      loadingTb:false,
      isActive: [],
      options:  {},
      summary:[],
      pieChartJumlah:[],
      pieChartTotal:[{
        total:0
      }],
      tableSatker:[],
      tableDetailPaket:[],
      tbData:[],
			data_color :["#00C5DC","#F4516C","#FFB822","#8859E0","#0C5484","#66BB6A","#00838F","#e57373"],
      seriesTotal: [],
      pieChartTotalOption: {},
      titleDetail:'',
      istableDetail:false,
      setUrut:0,
      isDiagramDetail:false,
      tableDetailPaketAll:[],
      yearFilter:[]
    }
},
   created() {
    this.$emit(`update:layout`, LayoutAdmin);   
    
  },
  mounted(){
   
    this.getSummary('pln')
    this.getListTahun()
    this.$nextTick(() => {
      $('.selectpicker').selectpicker('render');
    })
    this.G_GetAny('kontraktual/per-satker/nested?sumberDana=pln&tahun='+this.G_yearNow).then(res => {
      this.tableSatker = res.data.content
    })
    
  },
  components: {
      myTable
  },
  computed: {
      setNumber: function () {
        console.log(this.setUrut);

        this.setUrut = this.setUrut +1

        console.log(this.setUrut);
        let waw = this.setUrut
        return waw
      },
     
  },
  methods:{
    getListTahun() {
      for (let index = this.G_yearNow; index >= (this.G_yearNow-2); index--) {
        this.yearFilter.push(index);
      }
    },
    paguDigram(nilai) {
      if ((nilai/1000000000) >= 1) {
        return this.G_numFormat((this.pieChartJumlah.data) ? (nilai)/1000000000 :0)
      }else{
        return this.G_numFormat((this.pieChartJumlah.data) ? (nilai)/1000000 :0)
      }
    },

    paguDigramSatuan(nilai) {
      if ((nilai/1000000000) >= 1) {
        return 'Miliar'
      }else{
        return 'Juta'
      }
    },
    toggleSub(key){
      console.log(key);
      this.isActive[key] = !this.isActive[key];

      if (this.isActive[key]) {
        $('#sub_'+key).hide();
      }else{
        $('#sub_'+key).show();

      }

      console.log(this.isActive[key]);
    },
    getSummary(type,tahun){
      let filter = '';
      if(tahun){
        filter += '?tahun='+tahun
      }else{
        filter += '?tahun='+this.G_yearNow
      }

      if (type) {
        if (type != 'all') {
          filter += '&sumberDana='+type
        }
      }

      this.G_GetAny('kontraktual/summary'+filter).then(res => {
        this.summary = res.data
      })

      this.G_GetAny('kontraktual/summary/byStatus/count/pie'+filter).then(res => {
        this.pieChartJumlah = res.data

        this.options = {
          legend : {
            show:false,
          },
          colors:  this.data_color,
          dataLabels: {
            enabled: false,
          },
          labels: this.pieChartJumlah.label,
         
          plotOptions: {
            pie: {
              customScale: 1,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show:true,
  
                  },
                  value: {
                    show:true,
                  },
                  total: {
                    show:true,
                  }
  
                }
              }
            }
          }
        }
      })

      this.G_GetAny('kontraktual/summary/byStatus/sum/pie'+filter).then(res => {
        this.pieChartTotal = res.data
        this.seriesTotal = [
          {
          data: this.pieChartTotal.data
        }]
        this.pieChartTotalOption = {
          chart: {
            height: 300,
            type: 'bar',
            events: {
              click: function(chart, w, e) {
                // console.log(chart, w, e)
              }
            }
          },
          colors:  this.data_color,
          plotOptions: {
            bar: {
              columnWidth: '70%',
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          labels:this.pieChartTotal.label,
          yaxis: {
            show: false,
            labels: {
              formatter: function (value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return "Rp "+ val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  
                // return "Rp "+ G_numFormat(value);
              }
            },
          },
          xaxis: {
            show: false,
            categories: this.pieChartTotal.label,
            labels: {
              style: {
                colors:  this.data_color,
                fontSize: '12px'
              }
            }
          }
        }
      })

      this.G_GetAny('kontraktual/per-satker/nested'+filter).then(res => {
        this.tableSatker = res.data.content
      })
    },
    cariData(){
      let sumberDana = 'pln'
      this.getSummary(sumberDana,$('#tahun').val());
    },
    detailPaket(kodeSatker){
      console.log(kodeSatker);

      urutan = 0
      
      this.G_GetAny('kontraktual/per-paket/nested?kdsatker='+kodeSatker+'&tahun='+this.G_yearNow).then(res => {
        this.tableDetailPaket = res.data.content[0].childs[0]

        console.log(this.tableDetailPaket);
        this.$refs.modal.open("DETAIL PAKET "+this.tableDetailPaket.nmsatker,1200)

        this.titleDetail = "DETAIL PAKET "+this.tableDetailPaket.nmsatker
      })
    },
    detailPaketAll(){

      urutan = 0
      this.G_GetAny('kontraktual/per-paket/nested?tahun='+this.G_yearNow+'&sumberDana=pln').then(res => {
        
        this.tableDetailPaketAll = res.data.content

        console.log(this.tableDetailPaketAll);

        this.$refs.modalAll.open("DETAIL SEMUA PAKET ",'90%')

        this.titleDetail = "DETAIL SEMUA PAKET "
      })
    },
    detailPaketStatus(status){

      console.log(status);
    
      this.istableDetail = true

      urutan = 0
      
      this.G_GetAny('kontraktual/per-paket/nested?sumberDana=pln&status='+status+'&tahun='+this.G_yearNow).then(res => {
        this.tableDetailPaket = res.data.content
        this.istableDetail = false
        this.$refs.modalPaket.open("Detail Paket "+status,'90%')

        this.titleDetail = "Detail Paket "+status
      })

    },
    detailPaketStatusDiagram(status){

      let title = status;
      if (status == 'belum kontrak') {
        status = 'proses lelang,belum lelang,batal'
        title = 'Belum Kontrak'
      }
      else if(status == 'terkontrak'){
        status = 'terkontrak,persiapan kontrak,myc lanjutan'
        title = 'Terkontrak'
      }
      else if(status == 'kontraktual'){
        status = 'proses lelang,belum lelang,terkontrak,persiapan kontrak,myc lanjutan,batal'
        title = 'Kontraktual'
      }
      else if(status == 'belum lelang'){
        status = 'belum lelang,batal'
        title = 'Belum Lelang'
      }

      this.isDiagramDetail = true

      urutan = 0
      
      this.G_GetAny('kontraktual/per-paket/nested?sumberDana=pln&status='+status+'&tahun='+this.G_yearNow).then(res => {
        this.tableDetailPaket = res.data.content
        this.$refs.modalPaket.open("Detail Paket "+title,'90%')
        this.titleDetail = "Detail Paket "+title
      this.isDiagramDetail = false

      })

    },
    setNoUrut(idx){
      
      // this.setUrut = this.setUrut +1

      // console.log(this.setUrut);
      // let waw = this.setUrut
      // return waw


      urutan++ 
      return urutan
    },
  }
}